import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "after-close": _vm.afterModalClose, "title": "Add Cluster", "width": 832 }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.closeModal } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary", "loading": _vm.isAdding }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v(" Add ")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 8 } }, [_c("select-input", { key: "clusterId", attrs: { "form-item": "", "label": "Cluster", "placeholder": "Select Cluster", "data-source": _vm.clusters, "source": "id", "source-label": "clusterName", "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("clusterId", $event);
    } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 8 } }, [_c("select-input", { key: "country", attrs: { "form-item": "", "label": "Country", "data-source": _vm.countries, "source": "country", "source-label": _vm.parseCountry, "all-in-value": "", "rules": "required", "default-active-first-option": "" }, on: { "change": _vm.onCountryChange } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 8 } }, [_c("select-input", { key: "brandGroup", attrs: { "form-item": "", "label": "BrandGroup", "placeholder": "Select BrandGroup", "data-source": _vm.brandGroups, "source": "brandGroup", "source-label": _vm.parseBrandGroup, "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("brandGroup", $event);
    } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("select-input", { key: "category", attrs: { "form-item": "", "label": "Category", "placeholder": "Select Category", "data-source": _vm.categories, "source": "category", "source-label": _vm.parseCategory, "rules": "required" }, on: { "change": _vm.onCategoryChange } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("select-input", { key: "subCategory", attrs: { "form-item": "", "label": "SubCategory", "placeholder": "Select SubCategory", "data-source": _vm.subCategories, "source": "subCategory", "source-label": _vm.parseSubCategory, "rules": "required" }, on: { "change": _vm.onSubCategoryChange } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("select-input", { key: "promotionGroup", attrs: { "form-item": "", "label": _vm.cluster.country === _vm.COUNTRY_AU_CODE ? "AUPromotionGroup" : "NZPromotionGroup", "placeholder": _vm.cluster.country === _vm.COUNTRY_AU_CODE ? "Select AUPromotionGroup" : "Select NZPromotionGroup", "data-source": _vm.promotionGroups, "source": "promotionGroup", "source-label": _vm.parsePromotionGroup, "default-active-first-option": "", "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("promotionGroup", $event);
    } } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const COUNTRY_AU_CODE = 1;
const COUNTRY_NAME = {
  AU: "Australia",
  NZ: "New Zealand"
};
const __vue2_script$1 = {
  name: "CreateGroupMappingL2",
  inject: ["resourceProps"],
  data() {
    const [
      clusterTransProps,
      clusterProps,
      countryProps,
      brandGroupProps,
      categoryProps,
      subCategoryProps,
      promotionGroupProps
    ] = this.resourceProps;
    const { apiUrl: apiUrl2 } = clusterTransProps;
    return {
      apiUrl: apiUrl2,
      clusterTransProps,
      clusterProps,
      countryProps,
      brandGroupProps,
      categoryProps,
      subCategoryProps,
      promotionGroupProps,
      COUNTRY_AU_CODE,
      visible: true,
      isAdding: false,
      cluster: {}
    };
  },
  computed: {
    clusters() {
      return this.clusterProps.crud.getList();
    },
    countries() {
      return this.countryProps.crud.getList().slice(0, 2).map((country) => ({
        ...country,
        countryName: COUNTRY_NAME[country.country]
      }));
    },
    brandGroups() {
      return this.brandGroupProps.crud.getList();
    },
    categories() {
      return this.categoryProps.crud.getList();
    },
    subCategories() {
      return this.subCategoryProps.crud.getList();
    },
    promotionGroups() {
      return this.promotionGroupProps.crud.getList();
    }
  },
  methods: {
    getQueryString(params) {
      let query = "";
      if (Object.keys(params).length === 0 || params.constructor !== Object) {
        return query;
      }
      Object.entries(params).forEach(([key, value]) => {
        query += value ? `${key}=${value}&` : "";
      });
      return query.slice(0, -1);
    },
    fetchList(resourceProps, params = {}, url = null) {
      const query = this.getQueryString(params);
      resourceProps.crud.deleteFilter("IsInactive");
      resourceProps.crud.fetchList(url, true, true, query, true);
    },
    fetchClusterList() {
      this.fetchList(this.clusterProps);
    },
    fetchBrandGroupList() {
      const { countryName } = this.cluster;
      this.fetchList(this.brandGroupProps, { country: countryName });
    },
    fetchCategoryList() {
      const { countryName } = this.cluster;
      this.fetchList(this.categoryProps, { country: countryName }, `${this.apiUrl}/web-analytics/common/dgp-categories`);
    },
    fetchSubCategoryList() {
      const { countryName, categoryId } = this.cluster;
      this.fetchList(this.subCategoryProps, {
        country: countryName,
        categoryId
      });
    },
    fetchPromotionGroupList() {
      const { countryName, subCategory } = this.cluster;
      if (countryName && subCategory) {
        this.fetchList(this.promotionGroupProps, {
          country: countryName,
          subCategory
        });
      }
    },
    parseCountry(option) {
      return `${option.country} - ${option.countryName}`;
    },
    parsePromotionGroup(option) {
      if (!option)
        return "";
      return `${option.promotionGroupDesc}`;
    },
    parseCategory(categoryOption) {
      return categoryOption.categoryDesc !== "" ? `${categoryOption.categoryDesc}` : "All";
    },
    parseSubCategory(subCategoryOption) {
      return subCategoryOption.subCategoryDesc !== "" ? `${subCategoryOption.subCategoryDesc}` : "All";
    },
    parseBrandGroup(option) {
      return option.brandGroupFullDesc !== "" ? `${option.brandGroupFullDesc}` : "All";
    },
    onCountryChange(value) {
      this.storeValue("country", value.countryId);
      this.storeValue("countryName", value.country);
      this.fetchBrandGroupList();
      this.fetchCategoryList();
      this.fetchPromotionGroupList();
    },
    onCategoryChange(value) {
      this.storeValue("categoryId", value);
      this.storeValue("category", value);
      this.fetchSubCategoryList();
    },
    onSubCategoryChange(value) {
      this.storeValue("subCategory", value);
      this.fetchPromotionGroupList();
    },
    storeValue(resourceKey, value) {
      this.cluster = {
        ...this.cluster,
        [resourceKey]: value
      };
      this.clusterTransProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    closeModal() {
      this.visible = false;
      this.isAdding = false;
    },
    submit() {
      this.isAdding = true;
      this.clusterTransProps.crud.submitEntity("create").then(() => {
        this.clusterTransProps.crud.fetchList();
        this.closeModal();
      }).catch(() => {
        this.isAdding = false;
      });
    },
    afterModalClose() {
      this.$router.push(this.clusterTransProps.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateGroupMappingL2 = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-group-mappingL2" }, [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": [
    "web-analytics.cluster-l2-trans",
    "web-analytics.common.clusters",
    "web-analytics.common.countries",
    "web-analytics.common.dgp-brand-groups",
    "web-analytics.common.dgp-categories.create",
    "web-analytics.common.dgp-sub-categories",
    "web-analytics.common.dgp-promotion-group"
  ], "resource-id-name": [
    "id",
    "id",
    "country",
    "brandGroup",
    "category",
    "subCategory",
    "promotionGroup"
  ], "redirect-route": ["/web-analytics/cluster-l2-trans"] } }, [_c("create-group-mapping-l2")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateGroupMappingL2
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
